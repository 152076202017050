<template>
  <!-- 如果from === 'message' 来着消息归档页面，则写死left -->
  <div ref="AIModal" :class="['AI-modal', from]" :style="{ top: top, left: from === 'message' ? '20px' : left, right: right, bottom: bottom}"> 
    <div v-if="!createLoading && !AIText" class="operation-cont">
      <img class="logo-operation" src="@/assets/img/ai/logo-operation.png" alt="">
      <a-button class="operation-item" @click="handleGetImgConntent(2)">
        <img src="@/assets/img/ai/icon_summary.svg" alt="">
        <div>AI 总结</div>
      </a-button>
      <a-button class="operation-item" @click="handleGetImgConntent(1)">
        <img src="@/assets/img/ai/icon_read.svg" alt="">
        <div>读取原文</div>
      </a-button>
    </div>
    <div v-else class="ai-content">
      <div v-if="!AIText" class="creating-cont">
        <div class="top">
          <lottie-player
            class="loading-img"
            autoplay
            loop
            mode="normal"
            src="/static/png/AILoading.json"
          />
          <span class="loading-text">正在生成中<label class="loading-dot">.</label><label class="loading-dot">.</label><label class="loading-dot">.</label></span>
        </div>
        <ul class="skeleton-cont">
          <li class="skeleton-item"></li>
          <li class="skeleton-item"></li>
        </ul>
      </div>
      <div v-else class="finish-cont">
        <div class="logo-cont">
          <img class="logo-finish" src="@/assets/img/ai/logo-finish.png" alt="">
          <span class="title">投资云AI助理</span>
        </div>
        <!-- 未知原因，wangeditor 的 onchange事件触发问题，莫名其妙 找不到啥原因，这里必须使用a-button，否则有问题 -->
        <a-button v-if="maxHeight" class="ai-text-cont" :style="{maxHeight: maxHeight + 'px'}" v-html="AIText"></a-button>
        <div v-if="isShowCom" class="copy-cont">
          <span class="copy-tip">AI生成的内容可能不准确，请谨慎使用</span>
          <a-button class="copy-btn" @click="handleCopy">
            <i class="iconfont icon-icon_copy" />
            <div>复制</div>
          </a-button>
        </div>
        <div class="operation-btn-cont">
          <a-button v-if="isShowCom" class="opr-item" @click="handleAddText">
            <i class="iconfont icon-icon_import" />
            <div>同步至跟进记录</div>
          </a-button>
          <!-- <a-button v-if="isShowCom" class="opr-item" @click="handleReplaceImg">
            <i class="iconfont icon-icon_copy" />
            <div>替换图片</div>
          </a-button> -->
          <a-button :class="['opr-item', {self: !isShowCom}]" @click="handleClose">
            <i class="iconfont icon-icon_fl3" />
            <div>退出</div>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onClickOutside } from '@vueuse/core'
import { useWindowSize } from '@vueuse/core'
import { AiAnalyzeImage } from '@/services/ai/index.js'
import { copyToClipBoard } from '@/utils/appTool.js'
import "@lottiefiles/lottie-player";

export default {
  name: 'RecordAIModal',
  props: {
    from: String,
    topNumber: Number,
    bottomNumber: Number,
    top: String,
    right: String,
    bottom: String,
    left: String,
    fileStream: Object,
  },
  computed: {
    isShowCom() {
      return this.AIText === '未提取到文字' ? false : true
    },
  },
  // watch: {
  //   fileStream: {
  //     handler(file) {
  //       if (!file) return
  //       this.handleGetImgConntent(file)
  //     },
  //     deep: true,
  //     immediate: true,
  //   }
  // },
  data() {
    return {
      createLoading: false,
      originAIText: '',
      AIText: '',
      maxHeight: 0,
    }
  },
  mounted() {
    const { AIModal } = this.$refs
    onClickOutside(AIModal, () => {
      this.handleClose()
    })
  },
  methods: {
    setHeight() {
      this.$nextTick(() => {
        const { height } = useWindowSize()
        if (this.bottomNumber) {
          this.maxHeight = height.value - this.bottomNumber - 200
        } else {
          this.maxHeight = height.value - this.topNumber - 200
        }
      })
    },
    handleGetImgConntent(type) {
      this.createLoading = true
      const params = new FormData()
      params.append('type', type)
      params.append('image', this.fileStream)
      AiAnalyzeImage({
        data: params
      }).then(res => {
        this.AIText = res?.replace(/\n/g, '<br />') || '未提取到文字'
        this.originAIText = res

        this.setHeight()
      }).finally(() => {
        this.createLoading = false
      })
    },
    handleReplaceImg() {
      this.$emit('replaceImg', this.originAIText)
    },
    handleAddText() {
      this.$emit('addText', this.originAIText)
    },
    handleCopy() {
      copyToClipBoard(this.originAIText)
      this.$message.success('复制成功')
    },
    handleClose() {
      this.$emit('closeAIModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.AI-modal {
  width: fit-content;
  display: flex;
  position: fixed;
  border-radius: 10px;
  background: linear-gradient(270deg, #FFFFFF 0%, #FEFCFF 100%);
  border: 1px solid #E5DBF3;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  transition: all .3s;
  &.message {
    width: 310px;
    .ai-content {
      width: 100%;
    }
    .finish-cont {
      min-width: 100% !important;
      width: 100%;
    }
  }
  .operation-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 247px;
    height: 52px;
    padding: 0 10px 0 30px;
    border-radius: 10px;
    position: relative;
    .logo-operation {
      position: absolute;
      top: 0;
      left: -24px;
      height: 52px;
    }
    .operation-item {
      display: flex;
      align-items: center;
      width: 100px;
      height: 32px;
      border-radius: 8px;
      padding: 6px 8px;
      gap: 8px;
      background: linear-gradient(91deg, #FFF7FF 0%, #EEF8FF 99%);
      transition: background .2s;
      cursor: pointer;
      &:hover {
        background: linear-gradient(91deg, #F9E8F9 0%, #E3F3FF 98%);
      }
    }
  }
  .ai-content {
    padding: 12px 16px;
    box-sizing: border-box;
    border-radius: 10px;
    background: url('@/assets/img/ai/bg.png') no-repeat;
    background-size: 100% auto;
    position: relative;
    .zanwei-btn {
      width: 100%;
      height: 100%;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: text;
    }
    .operation-btn-cont {
      display: flex;
      align-items: center;
      padding: 0 4px;
      box-sizing: border-box;
      margin-top: 12px;
      .opr-item {
        min-width: 66px;
        height: 32px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 0px 8px;
        gap: 6px;
        margin-right: 8px;
        background: #FFFFFF;
        cursor: pointer;
        transition: background .2s;
        &:hover {
          background: rgba(31, 35, 41, 0.08);
        }
        &:last-child {
          margin-right: 0;
        }
        &.self {
          margin-left: auto;
        }
      }
    }
    .copy-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4px;
      box-sizing: border-box;
      .copy-tip {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        color: #86909C;
      }
      .copy-btn {
        width: 60px;
        height: 24px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 0px 6px;
        gap: 4px;
        background: #FFFFFF;
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #1F2329;
        cursor: pointer;
        transition: background .2s;
        &:hover {
          background: rgba(31, 35, 41, 0.08);
        }
        .iconfont {
          font-size: 16px;
          color: #646A73;
        }
      }
    }
    .finish-cont {
      min-width: 360px;
      max-width: 560px;
      .ai-text-cont {
        display: block;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin: 10px 0 8px 0;
        padding: 0 4px;
        border: 0;
        border-radius: 0;
        white-space: normal;
        background: transparent;
        box-sizing: border-box;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: #1F2329;
        text-align: left;
        cursor: text;
      }
      .logo-cont {
        display: flex;
        align-items: center;
        gap: 6px;
        .logo-finish {
          width: 36px;
        }
        .title {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          background: linear-gradient(90deg, #8743F1 0%, #2261F4 98%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          z-index: 1;
        }
      }
    }
    .creating-cont {
      width: 360px;
      height: 124px;
      .top {
        display: flex;
        align-items: center;
        gap: 6px;
      }
      .loading-img {
        width: 36px;
        height: 36px;
      }
      .loading-text {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #1F2329;
        .loading-dot {
          display: inline-block;
          animation: dot-move 1s ease-in-out infinite;
          &:nth-child(2) {
            animation-delay: 0.1s;
          }
          &:nth-child(3) {
            animation-delay: 0.2s;
          }
        }
      }
      .skeleton-cont {
        padding: 0 8px;
        box-sizing: border-box;
        margin-top: 28px;
        .skeleton-item {
          width: 242px;
          height: 14px;
          border-radius: 4px;
          background: linear-gradient(90deg, #FFF7FF 0%, #EEF8FF 99%), #D8D8D8;
          margin-bottom: 12px;
          animation: skeleton-loading 1s ease-in-out infinite;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @keyframes skeleton-loading {
    0% {
      opacity: 1;
      transform: scaleY(1);
    }
    50% {
      opacity: 0.7;
      transform: scaleY(0.7);
    }
    100% {
      opacity: 1;
      transform: scaleY(1);
    }
  }
  @keyframes dot-move {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-4px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
</style>
