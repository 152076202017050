import {ajax} from '@/utils/request';

// 详情页数据同步
export async function syncLarkFile(extra) {
  const result = await ajax({
    method: 'get',
    url: '/file/syncLarkFile',
    ...extra,
  }, [], []);
  return result;
}

// 新建项目
export async function beforeProjectAdd(extra,type) {
    const result = await ajax({
      method: 'post',
      url: `/${type}/add`,
      ...extra,
    }, [], []);
    return result;
}
// 通过分享落库投资云新建项目
export async function beforeProjectShareAdd(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/${type}/share/add`,
    ...extra,
  }, [], []);
  return result;
}
// 获取字段信息
export async function queryFieldList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/field/queryFieldList',
    ...extra,
  }, [], []);
  return result;
}
// 新的获取字段信息
export async function queryCreateRequiredList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/field/queryCreateRequiredList',
    ...extra,
  }, [], []);
  return result;
}
export async function queryRequiredList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/field/queryRequiredList',
    ...extra,
  }, [], []);
  return result;
}
// 获取选填字段信息
export async function queryEditFieldList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/field/queryEditFieldList',
    ...extra,
  }, [], []);
  return result;
}
// 添加字段
export async function addFiledsfun(extra) {
  const result = await ajax({
    method: 'post',
    url: '/field/add',
    ...extra,
  }, [], []);
  return result;
}
// 更新字段
export async function updateFiledsfun(extra) {
  const result = await ajax({
    method: 'post',
    url: '/field/update',
    ...extra,
  }, [], []);
  return result;
}
// 删除字段
export async function deleteFiledsfun(extra) {
  const result = await ajax({
    method: 'post',
    url: '/field/delete',
    ...extra,
  }, [], []);
  return result;
}

// 获取公司信息
export async function companySearch(extra) {
  const result = await ajax({
    method: 'get',
    url: '/insight/company/search',
    ...extra,
  }, [], []);
  return result;
}
// 获取公司信息 免登录
export async function companySearchLoginSkip(extra) {
  const result = await ajax({
    method: 'get',
    url: '/insight/company/searchLoginSkip',
    ...extra,
  }, [], []);
  return result;
}
// 获取cloud基金 免登录
export async function fundSearchLoginSkip(extra) {
  const result = await ajax({
    method: 'get',
    url: '/insight/fund/searchLoginSkip',
    ...extra,
  }, [], []);
  return result;
}

// 获取cloud基金
export async function fundSearch(extra) {
  const result = await ajax({
    method: 'get',
    url: '/insight/fund/search',
    ...extra,
  }, [], []);
  return result;
}

// 获取舆情公司信息
export async function companySearchTwo(extra) {
  const result = await ajax({
    method: 'post',
    url: '/publicOpinion/company/search',
    ...extra,
  }, [], []);
  return result;
}

// 获取地区，行业
export async function findAreaTree(extra) {
  const result = await ajax({
    method: 'post',
    url: '/selectOption/findAreaTree',
    ...extra,
  }, [], []);
  return result;
}
// 获取地区，行业 免登录
export async function findAreaTreeLoginSkip(extra) {
  const result = await ajax({
    method: 'post',
    url: '/selectOption/findAreaTreeLoginSkip',
    ...extra,
  }, [], []);
  return result;
}

// 详情页更新数据
export async function updateOrDelete(extra,type) {
  const result = await ajax({
    method: 'post',
    url:  `/${type}/updateOrDelete`,
    ...extra,
  }, [], []);
  return result;
}
// 变更公司主体
export async function updateProject(extra,type) {
  const result = await ajax({
    method: 'post',
    url:  `/${type}/updateProject`,
    ...extra,
  }, [], []);
  return result;
}
// 换logo，修改公司名称
export async function updatePart(data, code = 'beforeProject') {
  return await ajax({
    method: 'post',
    url: `/${code}/updatePart`,
    data,
  })
}
export const updatePartData = (data, code = 'beforeProject') => {
  return ajax({
    method: 'post',
    url: `/${code}/updatePartData`,
    data,
  })
}
// 批量修改字段信息
export const batchUpdatePartData = (data, code = 'beforeProject') => {
  return ajax({
    method: 'post',
    url: `/${code}/batchUpdatePartData`,
    data,
  })
}
// 获取审批列表
export async function approvalList(dataId) {
  const result = await ajax({
    method: 'get',
    url: `/approval/list/${dataId}`,
  }, [], []);
  return result;
}
// 获取审批实例列表（我发起）
export async function approvalInstanceQuery(data) {
  const result = await ajax({
    method: 'post',
    url: `/approval/instance/query`,
    data,
  }, [], []);
  return result;
}
// 获取审批实例列表（抄送我）
export async function approvalInstanceCcQuery(data) {
  const result = await ajax({
    method: 'post',
    url: `/approval/instance/cc/query`,
    data,
  }, [], []);
  return result;
}
// 获取审批实例列表（待办、已办）
export async function approvalInstanceTaskQuery(data) {
  const result = await ajax({
    method: 'post',
    url: `/approval/instance/task/query`,
    data,
  }, [], []);
  return result;
}
// 删除审批
export async function approvalInstanceUnrelate(id) {
  const result = await ajax({
    method: 'get',
    url: `/approval/instance/unrelate/${id}`,
  }, [], []);
  return result;
}
// 关联审批
export async function approvalInstanceRelate(data) {
  const result = await ajax({
    method: 'post',
    url: `/approval/instance/relate`,
    data,
  }, [], []);
  return result;
}
// 批量关联审批
export async function approvalBatchInstanceRelate(data) {
  const result = await ajax({
    method: 'post',
    url: `/approval/batch/instance/relate`,
    data,
  }, [], []);
  return result;
}
// 获取审批定义
export async function approvalDefinition(businessCode, assetTypeId) {
  const result = await ajax({
    method: 'get',
    url: `/approval/definition/${businessCode}${assetTypeId?'?assetTypeId=' + assetTypeId : ''}`,
  }, [], []);
  return result;
}
// 审批定义列表-后台管理
export async function approvalDefinitionBackstage(params) {
  const result = await ajax({
    method: 'get',
    url: `/approval/definition/backstage/${params.type}`,
    ...params
  }, [], []);
  return result;
}
// 批量更新审批定义(主要用来拖动排序)
export async function approvalDefinitionBatchUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/approval/definition/batch/update`,
    ...extra,
  }, [], []);
  return result;
}
// 审批定义code校验
export async function approvalDefinitionName(extra) {
  const result = await ajax({
    method: 'get',
    url: `/approval/definition/name/${extra}`,
  }, [], []);
  return result;
}
// 创建审批定义
export async function approvalDefinitionCreate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/approval/definition/create',
    ...extra,
  });
  return result;
}
// 更新审批定义
export async function approvalDefinitionUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/approval/definition/update',
    ...extra,
  });
  return result;
}
// 投资流程关联审批查询
export async function investFlowCheckUsedApproval(extra) {
  const result = await ajax({
    method: 'post',
    url: '/investFlow/checkUsedApproval',
    ...extra,
  });
  return result;
}
// 投资流程关联审批查询
export async function approvalDefinitionDelete(extra) {
  const result = await ajax({
    method: 'post',
    url: `/approval/definition/delete/${extra}`,
    ...extra,
  });
  return result;
}
// 获取审批定义表单
export async function definitionForm(extra) {
  const result = await ajax({
    method: 'get',
    url: `/approval/definition/form/${extra}`,
  },{
    getContext: true
  }, [], []);
  return result;
}
// 提交审批表单
export async function approvalCreate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/approval/instance/create',
    ...extra,
  },{
    getContext: true,
  });
  return result;
}
// 获取新建个人信息
export async function getSelfInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/user/getSelfInfo`,
  }, [], []);
  return result;
}

// 投资流程
// 获取节点
export async function findNodeInstances(extra) {
  const result = await ajax({
    method: 'get',
    url: `/investFlow/instance/findNodeInstances`,
    ...extra,
    // header:{
    //   "Content-Type":'application/x-www-form-urlencoded'
    // }
  }, [], []);
  return result;
}
// 变更阶段
export async function activeNode(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investFlow/instance/activeNode`,
    ...extra,
    // header:{
    //   "Content-Type":'application/x-www-form-urlencoded'
    // }
  },{
    getContext: true,
  });
  return result;
}
// 激活阶段检查
export async function instanceCheckActive(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investFlow/instance/checkActive`,
    ...extra,
    // header:{
    //   "Content-Type":'application/x-www-form-urlencoded'
    // }
  }, [], []);
  return result;
}

// 变更阶段 根据阶段名(看板视图使用)
export async function activeNodeById(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investFlow/instance/activeNode`,
    ...extra,
  }, [], []);
  return result;
}
// 待办事项
export async function instanceCheckItem(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investFlow/instance/checkItem`,
    ...extra,
    // header:{
    //   "Content-Type":'application/x-www-form-urlencoded'
    // }
  }, [], []);
  return result;
}

// 详情联系人
// 获取信息
export async function findByDataId(extra) {
  const result = await ajax({
    method: 'get',
    url: `/contacts/findByDataId`,
    ...extra,
  }, [], []);
  return result;
}
// 新增联系人
export async function createCont(extra) {
  const result = await ajax({
    method: 'post',
    url: '/contacts/create',
    headers:{
      "Content-Type": "multipart/form-data"
    },
    ...extra,
  },);
  return result;
}
// 编辑联系人
export async function contactsUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/contacts/update',
    headers:{
      "Content-Type": "multipart/form-data"
    },
    ...extra,
  },);
  return result;
}
// 删除联系人
export async function contactsDelete(extra) {
  const result = await ajax({
    method: 'post',
    url: `/contacts/delete`,
    ...extra,
  }, [], []);
  return result;
}

// 基金概况
// 投资情况
export async function fundinvest(extra) {
  const result = await ajax({
    method: 'get',
    url: `/fund/overview/invest`,
    ...extra,
  }, [], []);
  return result;
}
// 退出情况
export async function fundexit(extra) {
  const result = await ajax({
    method: 'get',
    url: `/fund/overview/exit`,
    ...extra,
  }, [], []);
  return result;
}
// 基金价值
export async function fundValue(extra) {
  const result = await ajax({
    method: 'post',
    url: `/fund/overview/fundValue`,
    ...extra,
  }, [], []);
  return result;
}
// 投资分布
export async function countInvest(extra) {
  const result = await ajax({
    method: 'get',
    url: `/fund/overview/countInvest`,
    ...extra,
  }, [], []);
  return result;
}
// 投资情况
export async function investDetail(extra) {
  const result = await ajax({
    method: 'post',
    url: `/fund/overview/investDetail`,
    ...extra,
  }, [], []);
  return result;
}
// 根据行业统计数量
export async function fundcountByIndustry(extra) {
  const result = await ajax({
    method: 'get',
    url: `/fund/overview/countByIndustry`,
    ...extra,
  }, [], []);
  return result;
}
// 获取不同资产类型&SPV下的统计数据
export async function fetchCountByIndustry(extra) {
  const result = await ajax({
    method: 'get',
    url: `/fund/overview/countAllAssetType?fundId=${extra.fundId}&endTime=${extra.endTime}`,
  }, [], []);
  return result;
}


// 根据地区统计数量
export async function fundcountByArea(extra) {
  const result = await ajax({
    method: 'get',
    url: `/fund/overview/countByArea`,
    ...extra,
  }, [], []);
  return result;
}
// IRR、MOC
export async function fundOverviewIrrAndMoc(extra) {
  const result = await ajax({
    method: 'post',
    url: `/fund/overview/irrAndMoc`,
    ...extra,
  }, [], []);
  return result;
}

//已投概况
// 投后概览
export async function getSummaryInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getSummaryInfo`,
    ...extra,
  }, [], []);
  return result;
}
// 持有情况
export async function getHolderInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getHolderInfo`,
    ...extra,
  }, [], []);
  return result;
}
// 投资情况
export async function getInvestInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getInvestInfo`,
    ...extra,
  }, [], []);
  return result;
}
// 投后概览-查询项目下基金
// 新的投资接口
export async function getSummary(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getSummary`,
    ...extra,
  }, [], []);
  return result;
}
export async function getInvestChart(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getInvestChart`,
    ...extra,
  }, [], []);
  return result;
}
// 投资概况-irr&moc图表
export async function getMocIrrChart(extra, code) {
  const result = await ajax({
    method: 'post',
    url: `/${code}/mocIrrChart`,
    ...extra,
  }, [], []);
  return result;
}
export async function getInvest(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getInvest`,
    ...extra,
  }, [], []);
  return result;
}
export async function queryFundByProjectId(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/queryFundByProjectId`,
    ...extra,
  }, [], []);
  return result;
}
// 获取审批定制化信息
export async function queryApproveInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/commonApprove/queryApproveInfo`,
    ...extra,
  }, [], []);
  return result;
}
export async function getSummaryChart(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getSummaryChart`,
    ...extra,
  }, [], []);
  return result;
}
export async function getValuationList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/getValuationList`,
    ...extra,
  }, [], []);
  return result;
}

// cloud 跳转
export async function getCloudHomePageUrl(extra) {
  const result = await ajax({
    method: 'get',
    url: `/insight/getCloudHomePageUrl`,
  }, [], []);
  return result;
}
// 检查重名
export async function checkIsExist(extra, code) {
  const result = await ajax({
    method: 'post',
    url: `/${code}/checkIsExist`,
    ...extra,
  });
  return result;
}

// 校验投资者名称是否重复
export async function lpInvestorCheckIsExist(extra) {
  const result = await ajax({
    method: 'post',
    url: `/lpInvestor/checkIsExist`,
    ...extra
  }, [], []);
  return result;
}

// 舆情设置
export async function publicOpinionAdd(extra) {
  const result = await ajax({
    method: 'post',
    url: `/publicOpinion/add`,
    ...extra,
  }, [], []);
  return result;
}
// 舆情设置
export async function publicOpinionQueryIndustry(extra) {
  const result = await ajax({
    method: 'get',
    url: `/publicOpinion/queryIndustry`,
  }, [], []);
  return result;
}
// 舆情详情列表
export async function publicOpinionQueryInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/publicOpinion/queryInfo`,
    ...extra,
  }, [], []);
  return result;
}
// 查询是否非项目群聊
export async function publicOpinionQueryIsProjectChat(extra) {
  const result = await ajax({
    method: 'post',
    url: `/publicOpinion/queryIsProjectChat`,
    ...extra,
  }, [], []);
  return result;
}
// 舆情详情批量查询公司
export async function companyList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/publicOpinion/company/list`,
    ...extra,
  }, [], []);
  return result;
}
// 舆情更新
export async function publicOpinionUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/publicOpinion/update`,
    ...extra,
  }, [], []);
  return result;
}
// 舆情列表
export async function queryMoreCompanyInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/publicOpinion/queryMoreCompanyInfo`,
    ...extra,
  }, [], []);
  return result;
}

// 后台布局列表查询
export async function queryLayoutList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/layout/queryLayoutList',
    ...extra,
  }, [], []);
  return result;
}
// 新增布局
export async function layoutupdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/layout/update`,
    ...extra,
  }, [], []);
  return result;
}
// 模糊查询字段
export async function queryField(extra) {
  const result = await ajax({
    method: 'get',
    url: `/field/queryField`,
    ...extra,
  }, [], []);
  return result;
}
// 查询列表
export async function queryList(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/${type}/queryList`,
    ...extra,
  }, [], []);
  return result;
}
// 获取已启用的资产类型列表
export async function assetTypeUsedList(extra,type) {
  const result = await ajax({
    method: 'get',
    url: `/assetType/queryAllUsedList`,
    ...extra,
  }, [], []);
  return result;
}
// 资产类型列表
export async function assetTypeAllList(extra,type) {
  const result = await ajax({
    method: 'get',
    url: `/assetType/queryAllList`,
    ...extra,
  }, [], []);
  return result;
}
// 更新资产类型
export async function assetTypeUpdate(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/assetType/update`,
    ...extra,
  }, [], []);
  return result;
}
// 拖动批量更新资产类型
export async function assetTypeBatchUpdate(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/assetType/batchUpdate`,
    ...extra,
  }, [], []);
  return result;
}
// 初始化资产类型
export async function assetTypeQueryAllBaseList(extra,type) {
  const result = await ajax({
    method: 'get',
    url: `/assetType/queryAllBaseList`,
    ...extra,
  }, [], []);
  return result;
}
// 币权投后持有情况
export async function transactionCoinGetHoldInfo(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/transactionCoin/getHoldInfo`,
    ...extra,
  }, [], []);
  return result;
}
// 币权投后持有情况图表
export async function transactionCoinGetHoldChart(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/transactionCoin/getHoldChart`,
    ...extra,
  }, [], []);
  return result;
}

// 获取用户数据权限
export async function modulePermission(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/user/modulePermission`,
    ...extra,
  }, [], []);
  return result;
}
// 获取股权权益变更&&资金流水对比数据
export async function fetchStockTransAndFundflow(extra) {
  const result = await ajax({
    method: 'post',
    url: `/investedCompany/investFlow/compare`,
    ...extra,
  }, [], []);
  return result;
}
// 获取子基金权益变更&&资金流水对比数据
export async function fetchFofTransAndFundflow(extra) {
  const result = await ajax({
    method: 'post',
    url: `/afterFof/investFlow/compare`,
    ...extra,
  }, [], []);
  return result;
}

// 获取子基金情况
export async function afterFofGetSummary(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/afterFof/getSummary`,
    ...extra,
  }, [], []);
  return result;
}
// 获取子基金估值
export async function afterFofGetValuationChart(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/afterFof/getValuationChart`,
    ...extra,
  }, [], []);
  return result;
}
// 获取子基金持有情况图标
export async function afterFofGetHoldChart(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/afterFof/getHoldChart`,
    ...extra,
  }, [], []);
  return result;
}
// 获取子基金持有情况
export async function afterFofGetHoldInfo(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/afterFof/getHoldInfo`,
    ...extra,
  }, [], []);
  return result;
}
// 子基金关联洞见机构数据
export async function orgInsightSearch(extra,type) {
  const result = await ajax({
    method: 'get',
    url: `/insight/org/search`,
    ...extra,
  }, [], []);
  return result;
}
// /api/mixTemplate/parseTemplateData 导入模板
export async function parseTemplateData(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/mixTemplate/parseTemplateData`,
    ...extra,
  }, [], []);
  return result;
}
// /api/mixTemplate/downLoad
export async function mixTemplateDownLoad(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/mixTemplate/downLoad`,
    ...extra,
  }, [], []);
  return result;
}
// 导入
export async function parseTemplateDataImport(extra,type='mixTemplate') {
  const result = await ajax({
    method: 'post',
    url: `/${type}/parseTemplateDataImport`,
    ...extra,
  }, [], []);
  return result;
}

// 关键指标导入
export async function quotaDataDownloadTemplate(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/downloadTemplate`,
    ...extra,
  },[], [])
  return result;
}
// 投资者管理导入
export async function fundLpDownloadTemplate(extra,type) {
  const result = await ajax({
    method: 'post',
    url: `/fundLp/downloadTemplate`,
    ...extra,
  },[], [])
  return result;
}
// 查询最后一次添加项目的成员
export async function queryLatestDataRights(extra) {
  const result = await ajax({
    method: 'get',
    url: `/datarights/queryLatestDataRights`,
    ...extra
  },[], [])
  return result;
}
// 查询最后一次添加项目的成员
export async function peDownLoad(extra) {
  const result = await ajax({
    method: 'post',
    url: `/transaction/pe/downLoad`,
    ...extra
  },[], [])
  return result;
}
// 查询公司信息获取简称
export async function insightSearchByCompanyName(nama) {
  const result = await ajax({
    method: 'get',
    url: `/insight/company/searchByCompanyName?keyword=${nama}`,
  },[], [])
  return result;
}