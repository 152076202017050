import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import store from './store';
import { isMobile } from '@/utils/browser.js'
import ArcoVue from '@arco-design/web-vue';
// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import { Message } from '@arco-design/web-vue'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'

import '@arco-design/web-vue/dist/arco.css';
//UI设计图标库
import '@/assets/icomoon/style.css';
import '@/assets/iconFont/iconfont.css';

import '@/assets/styles/arco-design-overload.scss'
import { useTable, initRenderer } from './composables/useTable';
import 'xe-utils'
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';

import "@/assets/styles/global.scss";

import { i18n } from './i18n-setup.js';
import { createVersionPolling } from "version-polling";

 

// setInterval(() => {
//   console.log('============test1============')
//   console.log('============test2============')
// }, 3000)

const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App);
app.config.globalProperties.$Message = Message;
const { MODE } = import.meta.env
if( MODE !== "development" ){
    createVersionPolling({
      appETagKey: "__APP_ETAG__",
    //   htmlFileUrl:'http://127.0.0.1:8080/upgrade.html',
      pollingInterval:10 * 60 * 1000, // 单位为毫秒  10分钟
      // pollingInterval: 3000, // 单位为毫秒  10分钟
    //   silent: process.env.NODE_ENV === "development", // 开发环境下不检测
      onUpdate: (self) => {
        // alert('版本已更新，即将自动刷新页面')
        self.onRefresh();
      },
    });
}

if (isMobile()) {
  const origin = location.origin
  const href = location.href
  location.href = href.replace(origin, `${origin}/h5`)
}

// import VConsole from 'vconsole'
// if (location.host === 'mindsite-dev.yuewe.com') {
//   setInterval(() => {
//   console.log('test白屏')
//   }, 3000)
  
  // const vConsole = new VConsole()
  // app.use(vConsole);
// }



app.use(router);
app.use(store);
app.use(pinia)
app.use(ArcoVue);
app.use(ArcoVueIcon);
// app.use(VXETable)

app.use(useTable)
app.use(i18n)

// initRenderer()

app.mount('#app');
