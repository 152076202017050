import { ajax } from '@/utils/request';

// 图片解析
export async function AiAnalyzeImage(data) {
  const result = await ajax({
    method: 'post',
    url: `/ai/analyze/image`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    ...data
  });
  return result;
}
// 上传本地文件
export async function quotaDataUpload(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/upload`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    signal: signal,
    data
  });
  return result;
}
// 上传本地文件
export async function quotaDataUploadLoginSkip(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/uploadLoginSkip`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    signal: signal,
    data
  });
  return result;
}
// 上传已有文件
export async function quotaDataUploadExistFile(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/uploadExistFile`,
    data
  });
  return result;
}
// 上传已有文件
export async function quotaDataUploadExistFileLoginSkip(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/uploadExistFileLoginSkip`,
    data
  });
  return result;
}
// 查询关键指标文件
export async function quotaDataQueryFile(dataId) {
  const result = await ajax({
    method: 'get',
    url: `/quotaData/queryFile?dataId=${dataId}`,
  });
  return result;
}
// 财报解析
export async function quotaDataAnalyzeReport(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/analyzeReport`,
    signal: signal,
    data,
  },{
    getContext: true,
  });
  return result;
}
// 财报解析
export async function quotaDataAnalyzeReportLoginSkip(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/analyzeReportLoginSkip`,
    signal: signal,
    data,
  },{
    getContext: true,
  });
  return result;
}
// 财报解析
export async function quotaDataCompare(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/compare`,
    data,
  });
  return result;
}
// 根据fileToken获取文件url
export async function fileQueryFileByToken(fileToken, fileType, signal) {
  const result = await ajax({
    method: 'get',
    url: `/file/queryFileByToken?fileToken=${fileToken}&fileType=${fileType}`,
    signal: signal,
  });
  return result;
}
// 根据fileToken获取文件url
export async function fileQueryFileByTokenLoginSkip(fileToken, fileType, tenantId, userId, signal) {
  const result = await ajax({
    method: 'get',
    url: `/file/queryFileByTokenLoginSkip?fileToken=${fileToken}&fileType=${fileType}&tenantId=${tenantId}&userId=${userId}`,
    signal: signal,
  });
  return result;
}
// 获取财务指标
export async function quotaGetFinanceQuota(assetTypeId) {
  const result = await ajax({
    method: 'post',
    url: `/quota/getFinanceQuota?assetTypeId=${assetTypeId}`,
  });
  return result;
}
// 文件归档
export async function quotaDataFileArchiving(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/fileArchiving`,
    data
  });
  return result;
}
// 文件归档
export async function fileChangeShareAuth(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/file/changeShareAuth`,
    signal: signal,
    data
  },{
    getContext: true,
  });
  return result;
}
// 查询AiCount
export async function tenantGetAiCount(extra) {
  const result = await ajax({
    method: 'get',
    url: `/tenant/getAiCount`,
  });
  return result;
}
// 查询AiCount
export async function tenantGetAiCountLoginSkip(tenantId) {
  const result = await ajax({
    method: 'get',
    url: `/tenant/getAiCountLoginSkip?tenantId=${tenantId}`,
  });
  return result;
}
// 查询AiCount
export async function tenantGetConfig(tenantId, key) {
  const result = await ajax({
    method: 'get',
    url: `/tenant/getConfig?tenantId=${tenantId}&key=${key}`,
  });
  return result;
}
// 查询最近一次编辑的投前或者投后项目
export async function getLastEditProject(extra) {
  const result = await ajax({
    method: 'get',
    url: `/mixData/queryLatestEditData?businessType=${extra?.businessType}`,
  });
  return result;
}