import { defineStore } from 'pinia'
import { fetchUseableCurrencyList, fetchCurrencyListLoginSkip } from '@/services/sysconfig/currency.js'
import { reactive, ref } from 'vue'

const mappings = reactive<Record<string, string>>({})
const currencySymbolList = ref<ICurrency[]>([])

export interface ICurrency { englishName: string; symbol: string; chineseName: string;exchangeRate: string; type: number, isEnable: boolean, isBase: boolean }

export function initCurrency(tenantId) {
  // fetchUseableCurrencyList().then((res: ICurrency[]) => {
    fetchCurrencyListLoginSkip(tenantId).then((res: ICurrency[]) => {
    const rs = res.reduce(
      (rs: Record<string, string>, i) => {
        rs[i.englishName] = i.symbol

        return rs
      },
      {} as Record<string, string>,
    )
    
    // 过滤未开启
    res = res.filter(item => item.isEnable)

    currencySymbolList.value = res
    Object.assign(mappings, rs)
  })
}

export const currencyMappingStore = defineStore({
  id: 'currencySymbolMapping',
  state: () => {
    return {
      mappings,
      currencySymbolList,
    }
  },
  actions: {
    getCurrencySymbol(currency: string) {
      return this.mappings[currency] ?? currency
    },
  },
  // persist: {
  //   enabled: true,
  //   strategies: [
  //     {
  //       storage: localStorage,
  //       paths: ['mappings'],
  //     },
  //   ],
  // },
})
