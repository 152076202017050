import { App, computed, nextTick, ref, StyleValue } from 'vue'
import {
  Input,
  InputNumber,
  DatePicker,
  Select,
  Option,
  Optgroup,
  Cascader,
  Image,
  Textarea,
  Tooltip,
  Doption,
  Dropdown,
} from '@arco-design/web-vue'
import 'xe-utils'
import VXETable, {
  // 全局对象
  // VXETable,

  // 表格功能
  // Footer,
  // Icon,
  Filter,
  Edit,
  // Menu,
  Export,
  // Keyboard,
  Validator,

  // 可选组件
  Column,
  // Colgroup,
  // Grid,
  // Tooltip,
  // Toolbar,
  // Pager,
  // Form,
  // FormItem,
  // FormGather,
  // Checkbox,
  // CheckboxGroup,
  // Radio,
  // RadioGroup,
  // RadioButton,
  // Switch,
  // Input,
  // Select,
  // Optgroup,
  // Option,
  // Textarea,
  // Button,
  // Modal,
  // List,
  // Pulldown,

  // 表格
  Table,
  VxeTableDefines,
  VxeGlobalRendererHandles,
} from 'vxe-table'
import moment from 'moment'
import { cloneDeep, pick, uniqBy } from 'lodash'
import { i18n } from '@/i18n-setup.js'
import { VueI18nTranslation } from 'vue-i18n'
// import zhCN from 'vxe-table/es/locale/lang/zh-CN'
import uuid from '@/utils/uuid'
import { FieldType, SpecialField } from '@/type'
import { formatCurrency } from '@/utils/currency'
import Iconfont from '@/components/Iconfont.vue'
import { formatSBJson } from '@/views/beforeInv/edit/transaction/utils'
import { fetchAreaTree, fetchCategoryTree } from '@/views/beforeInv/listFilter/services'
import EmptyContent from '@/components/emptyContent.vue'
import TagSelect from '@/components/tagSelect/tagSelect.vue'
import TagList from '@/components/form/fields/tagSelect/tagList.vue'
import Area from '@/components/form/fields/area/index.vue'
import UserTagList from '@/components/tagUsers/tagList.vue'
import UserTagSelect from '@/components/tagUsers/tagUserSelect.vue'
import LocaleStringInput from '@/components/LocaleStringInput.vue'
import CurrencyInput from '@/components/currencyInput/index.vue'
import { fetchStaffList } from '@/views/beforeInv/listFilter/services'
import { fetchFindAllUser } from '@/views/beforeInv/listFilter/services'
import { findOrganization } from '@/services/use.js'
import { STAGE_MAPPING } from '@/assets/constants/mappings.js'
import { fetchFundList, fetchAllFundList } from '@/views/beforeInv/edit/transaction/services'
import FileField from '@/views/components/FileFieldComp'
import FileIcon from '@/components/fileIcon.vue'
import RenderFileTags from '@/components/form/fields/fileField/renderFileTags.vue'
import LastRecord from '@/views/components/lastRecord/index.vue'
import InputFiled from '@/components/inputFiled.vue'
import AutoTooltip from '@/components/AutoTooltip.vue'
import jsFloatCompute from '@/utils/jsFloatCompute'
import { useTimerSummary } from '@/composables/useTimerSummary'
import {
  transformTimestamp,
  translformDate,
} from '@/views/beforeInv/edit/investObject/reportEnumsFormat.js'

const i18n_t: VueI18nTranslation = i18n.global.t
const getStateStyle = (state: string): StyleValue => {
  switch (state) {
    case i18n_t('plat_c.app_c.list_page.list_table.jinrutouhou'): // i18n_t('plat_c.app_c.list_page.list_table.jinrutouhou')
      return {
        background: '#E6F7F4',
        border: '1px solid #AFD3CF',
      }
    case i18n_t('plat_c.app_c.list_page.list_table.fangqixiangmu'):
      return {
        background: '#ECEEF1',
        border: '1px solid #C4CDDB',
      }
    case i18n_t('plat_c.app_c.list_page.list_table.quanbutuichu'):
      return {
        background: '#ECEEF1',
        border: '1px solid #C4CDDB',
      }
    case i18n_t('plat_c.app_c.list_page.list_table.bufentuichu'):
      return {
        background: '#E6F7F4',
        border: '1px solid #AFD3CF',
      }
    default:
      return {
        background: '#E7F6FF',
        border: '1px solid #B8DDFF',
      }
  }
}

export class TreeOptions {
  private _instance?: TreeOptions = undefined
  private areaOptions = ref<any[]>([])
  private categoryOptions = ref<any[]>([])
  private staffList = ref<any[]>([])
  private allUserList = ref<any[]>([])
  private organizationOptions = ref<any[]>([])
  private fundOptions = ref<any[]>([])
  private allFundOptions = ref<any[]>([])

  constructor() {
    fetchAreaTree().then((res: any[]) => {
      this.areaOptions.value = res
    })
    fetchCategoryTree().then((res: any[]) => {
      this.categoryOptions.value = res
    })
    fetchStaffList().then((res: any[]) => {
      this.staffList.value = res.map((i) => {
        return {
          ...i,
          value: i.id,
          label: i.name,
        }
      })
    })
    fetchFindAllUser().then((res: any[]) => {
      this.allUserList.value = res.map((i) => {
        return {
          ...i,
          value: i.id,
          label: i.name,
        }
      })
    })
    findOrganization().then((res: any[]) => {
      this.organizationOptions.value = res
    })
    fetchFundList().then((res: any[]) => {
      this.fundOptions.value = res
    })
    fetchAllFundList().then((res: any[]) => {
      this.allFundOptions.value = res
    })
    // TODO: fetch file list
  }

  getAreaOptions(flat = false) {
    if (flat) {
      return flatTreeOptions(this.areaOptions.value)
    }
    return this.areaOptions.value
  }
  getCategoryOptions(flat = false) {
    if (flat) {
      return flatTreeOptions(this.categoryOptions.value)
    }
    return this.categoryOptions.value
  }
  getStaffOptions() {
    return this.staffList.value
  }
  getAllUserList() {
    return this.allUserList.value
  }
  getOrganizationOptions() {
    return this.organizationOptions.value
  }
  getFundOptions() {
    return this.fundOptions.value
  }
  getAllFundOptions() {
    return this.allFundOptions.value
  }

  getInstance() {
    if (this._instance) {
      return this._instance
    } else {
      this._instance = new TreeOptions()

      return this._instance
    }
  }
}

export function useTable(app: App) {
  // 表格功能
  app
    // .use(Footer)
    // .use(Icon)
    // .use(Filter)
    // .use(Edit)
    // .use(Menu)
    // .use(Export)
    // .use(Keyboard)
    // .use(Validator)

    // 可选组件
    // .use(Column)
    // .use(Colgroup)
    // .use(Grid)
    // .use(Tooltip)
    // .use(Toolbar)
    // .use(Pager)
    // .use(Form)
    // .use(FormItem)
    // .use(FormGather)
    // .use(Checkbox)
    // .use(CheckboxGroup)
    // .use(Radio)
    // .use(RadioGroup)
    // .use(RadioButton)
    // .use(Switch)
    // .use(Input)
    // .use(Select)
    // .use(Optgroup)
    // .use(Option)
    // .use(Textarea)
    // .use(Button)
    // .use(Modal)
    // .use(List)
    // .use(Pulldown)

    // 安装表格
    // .use(Table)
    .use(VXETable)

  // 给 vue 实例挂载内部对象，例如：
  // app.config.globalProperties.$XModal = VXETable.modal
  // app.config.globalProperties.$XPrint = VXETable.print
  // app.config.globalProperties.$XSaveFile = VXETable.saveFile
  // app.config.globalProperties.$XReadFile = VXETable.readFile
}

const TEXT = 'TEXT'
const VARCHAR = 'VARCHAR'
const SELECT = 'SELECT'
const NUMBER = 'NUMBER'
const DATE = 'DATE'
const PERCENTAGE = 'PERCENTAGE'
const EMPLOYEE = 'EMPLOYEE'
const CURRENCY = 'CURRENCY'
const LINKAGE_AREA = 'LINKAGE_AREA'
const LINKAGE_CATEGORY = 'LINKAGE_CATEGORY'
const MULTI_SELECT = 'MULTI_SELECT'
const FILE = 'FILE'

const DEFAULT = 'DEFAULT'
const EMPTY = 'EMPTY'

const NAME_RENDERER = 'NAME_RENDERER'
const STAGE_RENDERER = 'STAGE_RENDERER'
const STATE_RENDERER = 'STATE_RENDERER'
const MODIFY_MAIN_BODY_RENDERER = 'MODIFY_MAIN_BODY_RENDERER'
const FOLLOW_RECORD_RENDERER = 'FOLLOW_RECORD_RENDERER'
const ACTION_RENDERER = 'ACTION_RENDERER'
const WEEK_REPORT_ACTION_RENDERER = 'WEEK_REPORT_ACTION_RENDERER'
const EXTERNAL_DATA_RENDERER = 'EXTERNAL_DATA_RENDERER'
const REPORT_DATE_RENDERER = 'REPORT_DATE_RENDERER'

type NormalRender = typeof EMPTY | typeof DEFAULT
export const RenderersMap: Record<FieldType | SpecialField | NormalRender, string> = {
  text: TEXT,
  varchar: VARCHAR,
  select: SELECT,
  multiSelect: MULTI_SELECT,
  number: NUMBER,
  date: DATE,
  percentage: PERCENTAGE,
  employee: EMPLOYEE,
  currency: CURRENCY,
  linkageArea: LINKAGE_AREA,
  linkageCategory: LINKAGE_CATEGORY,
  file: FILE,

  projectName: NAME_RENDERER,
  investorName: NAME_RENDERER,
  fundName: NAME_RENDERER,
  name: NAME_RENDERER,
  stage: STAGE_RENDERER,
  state: STATE_RENDERER,
  companyFullName: MODIFY_MAIN_BODY_RENDERER,
  fullName: MODIFY_MAIN_BODY_RENDERER,
  followRecord: FOLLOW_RECORD_RENDERER,
  externalData: EXTERNAL_DATA_RENDERER,
  action: ACTION_RENDERER,
  weekReportAction: WEEK_REPORT_ACTION_RENDERER,
  reportDate: REPORT_DATE_RENDERER,


  EMPTY: EMPTY,
  DEFAULT: DEFAULT,
}

const onPreviewFile = (url: LocalFile['url']) => {
  url && window.open(url)
}

export const renderTags = (props: { opts: any[]; type: 'user' | 'normal' | 'file' }) => {
  const { opts = [], type } = props || {}
  const isUserTag = type === 'user'
  const isFileTag = type === 'file'

  return (
    <div
      class={{
        'tag-wrapper': true,
        'tag-wrapper--user': isUserTag,
        'tag-wrapper--file': isFileTag,
      }}
    >
      {opts.map((opt) => {
        const isMoreTag = opt.cType === 'more'
        const style = isMoreTag
          ? {}
          : isUserTag || isFileTag
          ? { background: 'transparent' }
          : { background: opt.colour }

        return (
          <span
            class={['table-tag', { more: isMoreTag, clickable: isFileTag }]}
            key={opt.id}
            style={style}
            onClick={(evt: Event) => {
              if (isFileTag) {
                evt.preventDefault()
                evt.stopPropagation()

                onPreviewFile(opt.url)
              }
            }}
          >
            {isUserTag &&
              opt.cType !== 'more' &&
              (opt.avatar ? (
                <img class="avatar-img" src={opt.avatar} />
              ) : (
                <span class="avatar-no-img">{opt.label[0]}</span>
              ))}

            {isFileTag && opt.cType !== 'more' && <FileIcon file={opt} size={16} />}

            {opt.displayedLabel !== opt.label ? (
              <Tooltip
                contentClass="file-field-col-content"
                content={opt.label}
                v-slots={
                  opt.restTags?.length && {
                    content: () => (
                      <div style="max-width: 320px;max-height: 200px;overflow-y: auto;">
                        {(opt.restTags ?? []).map((f: any) => {
                          return (
                            <div
                              class="tooltip-file-tag-item"
                              onClick={(evt: Event) => {
                                if (isFileTag) {
                                  evt.preventDefault()
                                  evt.stopPropagation()

                                  onPreviewFile(f.url)
                                }
                              }}
                            >
                              <FileIcon file={f} size={16} />
                              <span class="label">{f.fileName}</span>
                            </div>
                          )
                        })}
                      </div>
                    ),
                  }
                }
              >
                <span class="label">{opt.displayedLabel}</span>
              </Tooltip>
            ) : (
              <span class="label">{opt.displayedLabel}</span>
            )}
          </span>
        )
      })}
    </div>
  )
}

export const flatTreeOptions = (opts: any[]): any[] => {
  return opts.reduce((rs, opt) => {
    const children: any[] = opt.child || opt.children
    const childrenOpts: any[] = children?.length ? flatTreeOptions(children) : []
    const childUsers = opt.childUser?.length ? opt.childUser : []

    return [...rs, opt, ...childrenOpts, ...childUsers]
  }, [])
}
const inited = ref(false)

const editDomRef = ref()
const focusComponent = () => {
  // 添加预览模式之后不需要自动聚焦了，故注释掉
  // handleFocus()
}
export const handleFocus = () => {
  nextTick(() => {
    if (typeof editDomRef.value?.focus === 'function') {
      editDomRef.value?.focus()
    } else {
      editDomRef.value?.$el.nextElementSibling?.click()
    }
  })
}

const useSetOpts = () => {
  const fieldTmpOpts: Record<string, any[]> = {}

  const setFieldOpts = (key: string, optsStr: string | null) => {
    // const opts = JSON.parse(optsStr ?? '').selectOptions ?? []

    let opts
    if (optsStr) {
      opts = JSON.parse(optsStr)?.selectOptions ?? []
    } else {
      opts = []
    }
    
    fieldTmpOpts[key] = opts
  }

  return {
    fieldTmpOpts,
    setFieldOpts,
  }
}

export const currentRendererRef = ref()

// TODO: renderCell 抽一下，重复代码有点多
export const initRenderer = () => {
  const { timeStart, timeEnd, getSummary } = useTimerSummary()
  const { fieldTmpOpts, setFieldOpts } = useSetOpts()
  if (inited.value) return

  inited.value = true
  // 初始化部分选项数据
  const treeOptionsInstance = TreeOptions.prototype.getInstance()

  const areaOptions = computed(() => {
    return treeOptionsInstance?.getAreaOptions()
  })
  const categoryOptions = computed(() => {
    return treeOptionsInstance?.getCategoryOptions()
  })
  const staffOptions = computed(() => {
    return treeOptionsInstance?.getStaffOptions()
  })
  const allUserList = computed(() => {
    return treeOptionsInstance?.getAllUserList()
  })
  const organizationOptions = computed(() => {
    return treeOptionsInstance?.getOrganizationOptions()
  })
  const flatOrganizationOptions = computed<any[]>(() => {
    const opts = treeOptionsInstance?.getOrganizationOptions()
    const flatOpts = flatTreeOptions(opts)
    const optIds: string[] = flatOpts.reduce((rs, i) => {
      return [...rs, ...(rs.includes(i.id) ? [] : [i.id])]
    }, [])

    return optIds.map((id) => flatOpts.find((i) => i.id === id))
  })
  const organizationLabelMap = computed(() => {
    return flatOrganizationOptions.value.reduce<Record<string, string>>((rs, opt) => {
      rs[opt.id] = opt.name || opt.deptName

      return rs
    }, {})
  })
  const allUserAndOrganizationLabelMap = computed(() => { // 所有人员（包含离职人员）和部门的 labelMap
    let allList = [...allUserList.value, ...flatOrganizationOptions.value]
    allList = uniqBy(allList, 'id')
    return allList.reduce<Record<string, string>>((rs, opt) => {
      rs[opt.id] = opt.name || opt.deptName

      return rs
    }, {})
  })
  const fundOptions = computed<any[]>(() => {
    return treeOptionsInstance?.getFundOptions()
  })

  // 地区各级可搜索
  const getPath = (data = [], parentName = '') => {
    data.forEach((item: any) => {
      let itemName = item.label
      if (parentName) {
        itemName = parentName + '-' + item.label
      }
      item.path = itemName
      if (item.children && item.children.length > 0) {
        getPath(item.children, itemName)
      }
    })
  }

  // 特殊组件
  VXETable.renderer.add(EMPTY, {
    renderEmpty(renderOpts, params) {
      return [<EmptyContent message={i18n_t('plat_c.app_c.empty_text.zanwushuju')} />]
    },
  })
  VXETable.renderer.add(DEFAULT, {
    renderCell() {
      return [<span>default</span>]
    },
  })

  // 字段类型渲染
  VXETable.renderer.add(SELECT, {
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const options: any[] =
        props?.columnData.config && props?.columnData.config !== 'null'
          ? JSON.parse(props?.columnData.config).selectOptions
          : []

      !props?.nonFocus && props?.renderEdit && focusComponent()
      const oldValue = row[column.field]

      const update = ((oldValue: any) => (value: any) => {
        events?.updateField(params, value ?? null, oldValue)
      })(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <TagSelect
                allow-search
                v-model:modelValue={row[column.field]}
                options={options}
                placeholder={i18n_t('plat_c.app_c.form.qingxuanze')}
                ref={(el: any) => (editDomRef.value = el)}
                default-popup-visible={true}
                optionKeys={{
                  label: 'label',
                  id: 'value',
                  color: 'colour',
                }}
                optionHeight="256px"
                popup-container=".overlay"
                onUpdate:modelValue={update}
              >
                {/* {options.map((opt) => {
                  return (
                    <Option
                      key={opt.value}
                      value={opt.value}
                      label={opt.label}
                      trigger-props={{ unmountOnClose: false }}
                      class={{ 'option-color': opt.color || true }}
                      style={{ '--bgc': opt.color || 'red' }}
                    />
                  )
                })} */}
              </TagSelect>
            </div>,
          ]
        : [<span>-</span>]
    },
    renderCell(renderOpts, params) {
      
      const timerId = uuid()
      timeStart(timerId)
      const { row, column } = params
      const { events, props } = renderOpts
      const optsExisted = Boolean(fieldTmpOpts[column.field])
      setFieldOpts(column.field, props?.columnData.config as string | null)
      const options: any[] = fieldTmpOpts[column.field]
      const tmp = row[column.field]
      const value = Array.isArray(tmp) ? tmp[0] : tmp

      const opt =
        ![undefined, null, ''].includes(value) && options.find((opt) => opt.value === value)
      const style = (opt?.colour ? { '--bgc': opt.colour, '--pd': '0 8px' } : {}) as StyleValue
      timeEnd(timerId, SELECT)
      return [
        opt?.label ? (
          <span class="select-option" style={style}>
            {opt?.label}
          </span>
        ) : // <span>-</span>
        column.field == 'currencyUnit' ? (
          <span class="select-option" style={style}>
            {value}
          </span>
        ) : 
        column.field == 'financingRound' && value ? (
          <span class="select-option" style={style}>
            {value}
          </span>
        ) : (
          <span>-</span>
        ), // 币种字段  关闭的币种也要现实
      ]
    },
  })
  VXETable.renderer.add(NUMBER, {
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts

      props?.renderEdit && focusComponent()

      // 哪个傻逼设计的，number 类型存 string
      if (typeof row[column.field] === 'string') {
        row[column.field] = Number(row[column.field])
      }
      const update = ((oldValue: any) => (value: any) => {
        // events?.updateField(params, value ?? null, oldValue)
        events?.updateField(params, value ? value + '' : null, oldValue)
      })(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <InputNumber
                placeholder={i18n_t('plat_c.app_c.form.qingshuru')}
                v-model:modelValue={row[column.field]}
                hide-button
                ref={(el: any) => (editDomRef.value = el)}
                onUpdate:modelValue={update}
              />
            </div>,
          ]
        : [<span></span>]
    },
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      // const tmp =
      //   typeof row[column.field] === 'string' ? Number(row[column.field]) : row[column.field]
      // const value = !['', null, undefined].includes(tmp) && !isNaN(tmp) ? formatCurrency(row[column.field]) : null
      const tmp =
        typeof row[`__displayed__${column.field}`] === 'string' ? Number(row[`__displayed__${column.field}`]) : row[`__displayed__${column.field}`]
      const value = !['', null, undefined].includes(tmp) && !isNaN(tmp) ? formatCurrency(row[`__displayed__${column.field}`]) : null

      timeEnd(timerId, NUMBER)

      return [<span>{value ?? '-'}</span>]
    },
  })
  VXETable.renderer.add(PERCENTAGE, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)
      const { row, column } = params

      const displayLabel = row[`__displayed__${column.field}`] || '-'
      timeEnd(timerId, PERCENTAGE)

      return [<span>{displayLabel}</span>]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const domRef = ref()
      const isFocused = ref(false)
      if (row[column.field] && typeof row[column.field] === 'string') {
        row[column.field] = Number(row[column.field])
      }

      props?.renderEdit && focusComponent()
      const update = ((oldValue: any) => (value: any) => {
        events?.updateField(params, value ?? null, oldValue)
      })(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <InputNumber
                placeholder={i18n_t('plat_c.app_c.form.qingshuru')}
                ref={(el: any) => (editDomRef.value = el)}
                v-model:modelValue={row[column.field]}
                v-slots={{
                  suffix: () => <span>%</span>,
                }}
                onUpdate:modelValue={update}
                hide-button
              />
            </div>,
          ]
        : [<span></span>]
    },
  })
  VXETable.renderer.add(DATE, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      const value = row[column.field]
      const existCalendar: Record<string, string> = row.existCalendar || {}
      const displayLabel = row[`__displayed__${column.field}`] || '-'

      timeEnd(timerId, DATE)

      return [
        <span>
          <span class="space-between">
            {value ? <span>{displayLabel || '-'}</span> : '-'}
            {(value || existCalendar[column.field]) && row.permission?.haveEdit ? (
              // <Iconfont
              //   name="fuzhi"
              //   style={{ color: existCalendar[column.field] ? '#3272FE' : 'currentColor' }}
              //   onClick={() =>
              //     typeof events?.openSchedule === 'function' && events?.openSchedule(row, column)
              //   }
              // />
              <span
                class="icon-icon_21 iconfont"
                style={{ color: existCalendar[column.field] ? '#3272FE' : '#8F959E' }}
                onClick={() =>
                  typeof events?.openSchedule === 'function' && events?.openSchedule(row, column)
                }
              />
            ) : (
              <span></span>
            )}
          </span>
        </span>,
      ]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const value = row[column.field]
      row[column.field] = value ? Number(value) : undefined

      props?.renderEdit && focusComponent()
      const update = ((oldValue: any) => (value: any) => {
        const tmp = value ? `${new Date(value).getTime()}` : null

        row[column.field] = tmp
        events?.updateField(params, tmp ?? null, oldValue)
      })(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <DatePicker
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                popup-container=".overlay"
                onUpdate:modelValue={update}
              />
            </div>,
          ]
        : [<span></span>]
    },
  })
  VXETable.renderer.add(CURRENCY, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      // const value = row[column.field] ? formatSBJson(row[column.field]) : null
      const displayLabel = row[`__displayed__${column.field}`]

      timeEnd(timerId, CURRENCY)

      return [
        <span style="display: block; text-align: right;">
          {![undefined, null, ''].includes(displayLabel?.num)
            ? `${displayLabel.currency ?? ''} ${displayLabel.label}`
            : '-'}
        </span>,
      ]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const displayLabel = row[`__displayed__${column.field}`]
      const currency = row[column.field]
      const value =
        currency && typeof currency === 'string'
          ? formatSBJson(currency)
          : currency || { num: null, currency: row.currencyUnit || 'CNY' }

      // if (typeof currency === 'string' || currency === null || currency === undefined) {
      //   Object.assign(row, {
      //     [column.field]: value,
      //   })
      //   // row[column.field] = value
      // } else {
      //   Object.assign(row[column.field], value)
      // }

      if (row[column.field].num && typeof row[column.field].num === 'string') {
        row[column.field].num = Number(row[column.field].num)
      }

      props?.renderEdit && focusComponent()

      const update = ((oldValue: any) => {
        return (value: any) =>
          events?.updateField(
            params,
            !['', null, undefined].includes(value?.num) ? JSON.stringify(value) : null,
            oldValue,
          )
      })(cloneDeep(row[column.field]))

      return props?.renderEdit
        ? [
            <div class="content">
              {/* <InputNumber */}
              <CurrencyInput
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                contentClassName={['table-currency-trigger', props?.bCode == 'fund' ? 'fund' : '']}
                hide-button
                switchable={props?.bCode == 'fund' ? false : true}
                currency={value.currency}
                placeholder={i18n_t('plat_c.app_c.form.qingshuru')}
                onUpdate:modelValue={update}
              />
            </div>,
          ]
        : [<span></span>]
    },
  })
  VXETable.renderer.add(EMPLOYEE, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts
      const onClick = (evt: any) => {
        events?.handlerClickRecord(evt)
      }
      let values: string[] | undefined = row[column.field]
        ? Array.isArray(row[column.field])
          ? row[column.field]
          : [row[column.field]]
        : undefined

      // 兼容周报看板，合并单元格的情况
      if (row.child?.length) {
        const array = row.child
        let html: any = []
        // row.child?.forEach((item: any, index: number) => {
        //   const value = item[column.field]
        //   values = Array.isArray(value) ? value : value ? [value] : []
        //   const computedTags = item[`__displayed__${column.field}`]
        //   const options = values?.map((v) => {
        //     return {
        //       label: organizationLabelMap.value,
        //       id: v,
        //     }
        //   })
        //   const showTagList = computedTags?.showTagList || []
        //   html.push(options?.length ? <p class={['overflow-cont no-pd', `${index}`]} data-index={index} data-lenth={array.length}><renderTags type="user" opts={showTagList} /></p> : <p class="overflow-cont"><span>-</span></p>)
        // })
        for (let index = 0; index < array.length; index++) {
          const value = array[index][column.field]
          values = Array.isArray(value) ? value : value ? [value] : []
          const computedTags = array[index][`__displayed__${column.field}`]
          const options = values?.map((v) => {
            return {
              // label: organizationLabelMap.value,
              label: allUserAndOrganizationLabelMap.value,
              id: v,
            }
          })
          const showTagList = computedTags?.showTagList || []
          html.push(options?.length ? <p class={['overflow-cont no-pd', `${index}`]} data-index={index} data-id={array[index].id} data-lenth={array.length} onClick={(evt) => onClick(evt)}>
            <renderTags type="user" opts={showTagList} />
            <span class='comment-num'>
              <i class='iconfont icon-icon_4' />
              <span class='num'>{array[index]?.commentNum || 0}</span>
            </span>
          </p> : <p class="overflow-cont"><span>-</span></p>)
        }
        return [html]
      }
      else {
        const computedTags = props?.isPreview ? undefined : row[`__displayed__${column.field}`]
        const state = props?.isPreview ? 1 : 0

        const options = values?.map((v) => {
          return {
            // label: organizationLabelMap.value,
            label: allUserAndOrganizationLabelMap.value,
            id: v,
          }
        })

        // 因为人员离职，这边可能会出现匹配不上的问题
        // if (options.length !== values?.length) {
        // }
        const showTagList = computedTags?.showTagList || []

        timeEnd(timerId, EMPLOYEE)

        return [options?.length ? <renderTags type="user" opts={showTagList} /> : <span>-</span>]
      }
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const value = row[column.field]
      // const options: any[] = staffOptions.value || []
      const options: any[] = allUserList.value || []

      const defaultVisible = props?.isPreview ? false : true
      !props?.nonFocus && props?.renderEdit && focusComponent()

      const update = (
        (oldValue: any) => (value: any) =>
          events?.updateField(params, value?.length ? value : null, oldValue)
      )(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <UserTagSelect
                v-model:modelValue={row[column.field]}
                placeholder={i18n_t('plat_c.app_c.form.qingxuanze')}
                options={options}
                ref={(el: any) => (editDomRef.value = el)}
                popup-container=".overlay"
                optionHeight="256px"
                type="multiple"
                defaultVisible={defaultVisible}
                onUpdate:modelValue={update}
              />
              {/* <Select
                allow-search
                v-model:modelValue={row[column.field]}
                placeholder="请选择"
                ref={(el: any) => (editDomRef.value = el)}
                popup-container=".overlay"
                onUpdate:modelValue={(value: any) => events?.updateField(params, value)}
              >
                {options.map((opt) => {
                  return (
                    <Option
                      key={opt.value}
                      value={opt.value}
                      label={opt.name}
                      trigger-props={{ unmountOnClose: false }}
                    >
                      <div class="staff-option">
                        <span class="avatar">
                          {opt.avatar ? (
                            <img src={opt.avatar} alt="" class="avatar__img" />
                          ) : (
                            <span class="avatar__text">{opt.name.slice(0, 1)}</span>
                          )}
                        </span>
                        <span class="name">{opt.name}</span>
                      </div>
                    </Option>
                  )
                })}
              </Select> */}
            </div>,
          ]
        : [<span></span>]
    },
  })
  VXETable.renderer.add(TEXT, {
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts

      props?.renderEdit && focusComponent()
      const update = (
        (oldValue: any) => (value: any) =>
          events?.updateField(params, value ?? null, oldValue)
      )(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <Textarea
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                placeholder={i18n_t('plat_c.app_c.form.qingshuru')}
                auto-size={{
                  minRows: 1,
                  maxRows: 5,
                }}
                max-length={5000}
                onUpdate:modelValue={update}
              />
            </div>,
          ]
        : [<span></span>]
    },
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      const onClick = (evt: any) => {
        events?.handlerClickRecord(evt)
      }
      let value = row[column.field]
      if (value) {
        value = value.replaceAll('\n', '<br />')
      }
      timeEnd(timerId, TEXT)

      // return [<span>{value || '-'}</span>]

      // 文本字段如果输入链接需要支持跳转
      // return [<InputFiled value={value} />]

      // 兼容周报看板，合并单元格的情况
      if (row.child?.length) {
        const array = row.child
        let html: any = []
        for (let index = 0; index < array.length; index++) {
          value = array[index][column.field]
          html.push(<p class={['overflow-cont', `${index}`]} data-index={index} data-id={array[index].id} data-lenth={array.length} v-html={value || '-'} onClick={(evt) => onClick(evt)} />)
        }
        return [html]
      }
      else {
        return [<InputFiled value={value} />]
      }
    },
  })
  VXETable.renderer.add(VARCHAR, {
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts

      props?.renderEdit && focusComponent()
      const update = (
        (oldValue: any) => (value: any) =>
          events?.updateField(params, value ?? null, oldValue)
      )(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <Input
                ref={(el: any) => (editDomRef.value = el)}
                v-model:modelValue={row[column.field]}
                placeholder={i18n_t('plat_c.app_c.form.qingshuru')}
                onUpdate:modelValue={update}
              />
            </div>,
          ]
        : [<span></span>]
    },
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      const onClick = (evt: any) => {
        events?.handlerClickRecord(evt)
      }
      let value = row[column.field]

      timeEnd(timerId, VARCHAR)

      // return [<span>{value || '-'}</span>]

      // 文本字段如果输入链接需要支持跳转
      // return [<InputFiled value={value} />]

      // 兼容周报看板，合并单元格的情况
      if (row.child?.length) {
        const array = row.child
        let html: any = []
        for (let index = 0; index < array.length; index++) {
          value = array[index][column.field]
          html.push(<p class={['overflow-cont', `${index}`]} data-index={index} data-id={array[index].id} data-lenth={array.length} v-html={value || '-'} onClick={(evt) => onClick(evt)} />)
        }
        return [html]
      }
      else {
        return [<InputFiled value={value} />]
      }
    },
  })
  VXETable.renderer.add(FILE, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts

      const computedTags = row[`__displayed__${column.field}`]
      const showTagList = computedTags?.showTagList || []

      timeEnd(timerId, FILE)

      return showTagList.length
        ? [<RenderFileTags type="file" opts={showTagList} />]
        : [<span>-</span>]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const value = row[column.field] ?? []
      const defaultTags = props?.columnData.defaultTags

      const update = ((oldValue: any) => (value: any) => {
        const fileFieldValue = value
          ? value.map((file: LocalFile) => {
              return file.id
            })
          : null

        row[column.field] = value
        events?.updateField(params, fileFieldValue ?? null, oldValue, value)
      })(row[column.field])

      return [
        <FileField
          popup-container=".overlay"
          ref={(el: any) => (currentRendererRef.value = el)}
          editable={row.permission?.haveEdit}
          fileList={value ?? []}
          dataId={row.id}
          fieldName={column.field}
          assetTypeId={row.assetTypeId}
          bCode={props?.bCode}
          defaultTags={defaultTags}
          defaultPopupVisible={false}
          onUpdate:fileList={update}
        />,
      ]
    },
  })
  VXETable.renderer.add(MULTI_SELECT, {
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const options: any[] =
        props?.columnData.config && props?.columnData.config !== 'null'
          ? JSON.parse(props?.columnData.config)?.selectOptions || []
          : []

      !props?.nonFocus && props?.renderEdit && focusComponent()
      const update = (
        (oldValue: any) => (value: any) =>
          events?.updateField(params, value ?? null, oldValue)
      )(row[column.field])

      return props?.renderEdit
        ? [
            <div class="content">
              <TagSelect
                allow-search
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                type="multiple"
                options={options}
                optionKeys={{
                  label: 'label',
                  id: 'value',
                  color: 'colour',
                }}
                optionHeight="256px"
                placeholder={i18n_t('plat_c.app_c.form.qingxuanze')}
                popup-container=".overlay"
                onUpdate:modelValue={update}
              >
                {/* {options.map((opt) => {
                  return (
                    <Option
                      key={opt.value}
                      value={opt.value}
                      label={opt.label}
                      trigger-props={{ unmountOnClose: false }}
                      class={{ 'option-color': opt.color }}
                      style={{ '--bgc': opt.color }}
                    />
                  )
                })} */}
              </TagSelect>
            </div>,
          ]
        : [<span></span>]
    },
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts

      const computedTags = row[`__displayed__${column.field}`]
      const showTagList = computedTags?.showTagList || []

      timeEnd(timerId, MULTI_SELECT)

      return showTagList.length
        ? [<renderTags type="normal" opts={showTagList} />]
        : [<span>-</span>]

      // const options: any[] =
      //   props?.columnData.config && props?.columnData.config !== 'null'
      //     ? JSON.parse(props?.columnData.config).selectOptions || []
      //     : column.field === 'investedFund'
      //     ? fundOptions.value
      //     : []

      // const opts: any[] = options
      //   .filter((opt) => value.includes(opt.value))
      //   .map((opt) => {
      //     return {
      //       ...opt,
      //       color: opt.colour || opt.color,
      //       id: opt.id || opt.value,
      //       label: opt.label || opt.name,
      //     }
      //   })

      // return [
      //   opts.length ? (
      //     <TagList from="normal" workerComputedData={computedTags} tags={opts} state={1} />
      //   ) : (
      //     // <renderTags opts={computedTags?.showTagList} />
      //     <span>-</span>
      //   ),
      // ]
    },
  })
  VXETable.renderer.add(LINKAGE_AREA, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts

      const displayLabel = row[`__displayed__${column.field}`] || '-'

      timeEnd(timerId, LINKAGE_AREA)

      return [<span>{displayLabel}</span>]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts

      props?.renderEdit && focusComponent()
      const update = (
        (oldValue: any) => (value: any) =>
          events?.updateField(params, value || null, oldValue)
      )(row[column.field])

      // 地区各级可搜索
      getPath(areaOptions.value)
      const filterOption = (value: any, option: any) => {
        // return option.path.includes(value)
        const lowerCaseValue = value?.toLowerCase() ?? ''
        const lowerCaseLabel = option.path?.toLowerCase() ?? ''

        return lowerCaseLabel.includes(lowerCaseValue)
      }
      // 地区各级可搜索 end

      const formatLabel = (options) => {
        const labels = options.map(option => option.label)
        return labels.join('-')
      }

      return props?.renderEdit
        ? [
            <div class="content">
              <Cascader
                class="table-cascader"
                path-mode
                allow-clear
                allow-search
                check-strictly
                options={areaOptions.value}
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                placeholder={i18n_t('plat_c.app_c.form.qingxuanze')}
                popup-container=".overlay"
                onUpdate:modelValue={update}
                filter-option={filterOption}
                format-label={formatLabel}
                v-slots={{
                  'search-icon': (
                    <i
                      class="icon-arrow iconfont icon-icon_d_arrow"
                      style="font-size: 12px;transform: scale(0.8333);transform-origin: center;color: #646a73;"
                    />
                  ),
                  'arrow-icon': (
                    <i
                      class="icon-arrow iconfont icon-icon_d_arrow"
                      style="font-size: 12px;transform: scale(0.8333);transform-origin: center;color: #646a73;"
                    />
                  ),
                }}
              />
            </div>,
          ]
        : [<span></span>]
    },
  })
  VXETable.renderer.add(LINKAGE_CATEGORY, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts

      const displayLabel = row[`__displayed__${column.field}`] || '-'

      timeEnd(timerId, LINKAGE_CATEGORY)

      return [<span>{displayLabel}</span>]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts

      props?.renderEdit && focusComponent()
      const update = (
        (oldValue: any) => (value: any) =>
          events?.updateField(params, value || null, oldValue)
      )(row[column.field])

      // 地区各级可搜索
      getPath(categoryOptions.value)
      const filterOption = (value: any, option: any) => {
        // return option.path.includes(value)
        const lowerCaseValue = value?.toLowerCase() ?? ''
        const lowerCaseLabel = option.path?.toLowerCase() ?? ''

        return lowerCaseLabel.includes(lowerCaseValue)
      }
      // 地区各级可搜索 end

      const formatLabel = (options) => {
        const labels = options.map(option => option.label)
        return labels.join('-')
      }

      return props?.renderEdit
        ? [
            <div class="content">
              <Cascader
                class="table-cascader"
                path-mode
                allow-clear
                allow-search
                check-strictly
                options={categoryOptions.value}
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                placeholder={i18n_t('plat_c.app_c.form.qingxuanze')}
                popup-container=".overlay"
                onUpdate:modelValue={update}
                filter-option={filterOption}
                format-label={formatLabel}
                v-slots={{
                  'search-icon': (
                    <i
                      class="icon-arrow iconfont icon-icon_d_arrow"
                      style="font-size: 12px;transform: scale(0.8333);transform-origin: center;color: #646a73;"
                    />
                  ),
                  'arrow-icon': (
                    <i
                      class="icon-arrow iconfont icon-icon_d_arrow"
                      style="font-size: 12px;transform: scale(0.8333);transform-origin: center;color: #646a73;"
                    />
                  ),
                }}
              />
            </div>,
          ]
        : [<span></span>]
    },
  })

  // 特殊字段处理
  VXETable.renderer.add(NAME_RENDERER, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts
      const logo = (row.logoBase64 || row.logo) as string | null
      const name: string = row[column.field] || row.name || ''

      const onClick = () => {
        events?.openDetail(row)
      }
      const isFund = props?.code === 'fund'

      timeEnd(timerId, NAME_RENDERER)

      return [
        <div class="content">
          {!row.isGroup ? <div class="project-name" onClick={() => onClick()} key={row.id}>
            {!isFund && (
              <div class="pic">
                {logo ? <img src={logo} alt="" /> : <span>{name.slice(0, 1)}</span>}
              </div>
            )}
            <div class="project-info">
              <div class="name">{name}</div>
            </div>
          </div> : <div class="group-tag-cont">
            <div class="tags-cont">
              <TagList from="normal" meta={{showAvatar: row.groupName[0]?.avatar ? true : false}} fontSize={14} tags={row.groupName} state={0} />
            </div>
            <span class="num">{row?.children?.length}条</span>
          </div>}
        </div>,
      ]
    },
  })

  VXETable.renderer.add(STAGE_RENDERER, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts
      const value = row[column.field]
      const options: any[] =
        props?.columnData.config && props?.columnData.config !== 'null'
          ? JSON.parse(props?.columnData.config).selectOptions
          : []
      // const style = (STAGE_MAPPING as Record<string, StyleValue>)[value] || null
      const opt = options?.find((i) => i.id === value)
      const style = opt?.color ? `background-color: ${opt?.color}` : null

      timeEnd(timerId, STAGE_RENDERER)

      return [
        <span class="stage auto-tips" style={style ? style : STAGE_MAPPING.default}>
          <AutoTooltip content={opt?.nodeName || '-'} />
          {/* {opt?.nodeName || '-'} */}
        </span>,
      ]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const options: any[] =
        props?.columnData.config && props?.columnData.config !== 'null'
          ? JSON.parse(props?.columnData.config).selectOptions
          : []

      !props?.nonFocus && props?.renderEdit && focusComponent()

      return props?.renderEdit
        ? [
            <div class="content">
              <TagSelect
                allow-search
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                options={options}
                optionKeys={{
                  label: 'label',
                  id: 'value',
                  color: 'colour',
                }}
                optionHeight="256px"
                placeholder={i18n_t('plat_c.app_c.form.qingxuanze')}
                popup-container=".overlay"
                onUpdate:modelValue={(value: any) =>
                  events?.updateField(params, value ?? null, row[column.field])
                }
              >
                {/* {options.map((opt) => {
                  return (
                    <Option
                      key={opt.value}
                      value={opt.value}
                      label={opt.label}
                      trigger-props={{ unmountOnClose: false }}
                      class={{ 'option-color': opt.color }}
                      style={{ '--bgc': opt.color }}
                    />
                  )
                })} */}
              </TagSelect>
            </div>,
          ]
        : [<span></span>]
    },
  })

  VXETable.renderer.add(STATE_RENDERER, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts
      const value = row[column.field]
      const optsExisted = Boolean(fieldTmpOpts[column.field])

      if (!optsExisted) {
        setFieldOpts(column.field, props?.columnData.config as string | null)
      }
      const options: any[] = fieldTmpOpts[column.field]
      const opt = options.find((o) => o.value === value)
      const style = opt?.label ? getStateStyle(opt?.label) : undefined

      timeEnd(timerId, STATE_RENDERER)

      return [
        <span class="state" style={style}>
          {opt?.label || value || '-'}
        </span>,
      ]
    },
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const options: any[] =
        props?.columnData.config && props?.columnData.config !== 'null'
          ? JSON.parse(props?.columnData.config).selectOptions
          : []

      !props?.nonFocus && props?.renderEdit && focusComponent()

      return props?.renderEdit
        ? [
            <div class="content">
              <TagSelect
                allow-search
                v-model:modelValue={row[column.field]}
                ref={(el: any) => (editDomRef.value = el)}
                options={options}
                placeholder={i18n_t('plat_c.app_c.form.qingxuanze')}
                popup-container=".overlay"
                optionHeight="256px"
                optionKeys={{
                  label: 'label',
                  id: 'value',
                  color: 'colour',
                }}
                onUpdate:modelValue={(value: any) =>
                  events?.updateField(params, value ?? null, row[column.field])
                }
              >
                {/* {options.map((opt) => {
                  return (
                    <Option
                      key={opt.value}
                      value={opt.value}
                      label={opt.label}
                      trigger-props={{ unmountOnClose: false }}
                      class={{ 'option-color': opt.color }}
                      style={{ '--bgc': opt.color }}
                    />
                  )
                })} */}
              </TagSelect>
            </div>,
          ]
        : [<span></span>]
    },
  })

  VXETable.renderer.add(MODIFY_MAIN_BODY_RENDERER, {
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const value = row[column.field]

      // props?.renderEdit && events?.modifyMainBody(params)

      return [
        <div style="max-height: 157px; overflow: auto;">
          <span
            style="cursor: pointer;padding: 9px;line-height: 20px;display: inherit;"
            onClick={() => events?.modifyMainBody(params)}
          >
            {value || '-'}
          </span>
        </div>,
      ]
    },
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      const value = row[column.field]

      timeEnd(timerId, MODIFY_MAIN_BODY_RENDERER)

      // return [<span>{ || '-'}</span>]
      return [<AutoTooltip content={value || '-'} />]
    },
  })

  VXETable.renderer.add(ACTION_RENDERER, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts
      const value = row[column.field]

      timeEnd(timerId, ACTION_RENDERER)
      return [
        <Dropdown
          onSelect={(key: string) => events?.onAction(row, key)}
          position="br"
          class="action-dropdown related"
          arrow-style="{ display: 'none' }"
          v-slots={{
            content: () => {
              return (
                <>
                  <Doption value="edit" disabled={row.isLock || !row.haveEdit || props?.columnData?.permission?.create == 0}>
                    <Iconfont name="icon_edit">编辑</Iconfont>
                  </Doption>
                  <Doption value="delete" disabled={row.isLock || !row.haveEdit || props?.columnData?.permission?.create == 0}>
                    <Iconfont name="icon_delete">删除</Iconfont>
                  </Doption>
                  { row?.haveApproval ? <Doption value="approvalRecord">
                    <Iconfont name="icon_vetting">审批记录</Iconfont>
                  </Doption> : ''}
                </>
              )
            },
          }}
        >
          <Iconfont name="icon_more_med1" style="cursor: pointer;padding: 4px;" />
        </Dropdown>,
      ]
    },
  })

  VXETable.renderer.add(WEEK_REPORT_ACTION_RENDERER, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      const value = row[column.field]

      timeEnd(timerId, WEEK_REPORT_ACTION_RENDERER)

      return [
        <span class="week-report-action-cont">
          <i class="iconfont icon-icon_edit" />
          <i class="iconfont icon-icon_delete" />
        </span>
      ]
    },
  })

  VXETable.renderer.add(REPORT_DATE_RENDERER, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      const value = row[column.field]

      timeEnd(timerId, REPORT_DATE_RENDERER)

      if (value && Number(value)) {
        const date = Number(value)
        const { quotaString } = transformTimestamp(date)
        const fullYear = new Date(date).getFullYear()
        const string = `${fullYear}年${quotaString}`

        return [<span>{string}</span>]
      }

      return [<span>-</span>]
    },
  })

  VXETable.renderer.add(EXTERNAL_DATA_RENDERER, {
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events } = renderOpts
      const value = row[column.field]
      const showPublicInfo = () => events?.showPublicInfo(params, value)

      timeEnd(timerId, EXTERNAL_DATA_RENDERER)

      return value
        ? [
            <span
              class={{ 'public-info': true, clickable: value.id }}
              onClick={() => value.id && showPublicInfo()}
            >
              {value.name}
            </span>,
          ]
        : [<span>-</span>]
    },
  })
  // 跟进记录
  VXETable.renderer.add(FOLLOW_RECORD_RENDERER, {
    renderEdit(renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const recordHtml: string = row[column.field]

      // props?.renderEdit && events?.modifyMainBody(params)
      const onRefreshTable = (record: string, followRecordId: string) => {
        events?.refreshTable({
          ...row,
          followRecord: record,
          followRecordId,
        })
      }
      return [
        <div class="readonly-preview-cell" style="max-height: 157px;">
          <div style="line-height: 20px;display: inherit;">
            <LastRecord
              recordHtml={recordHtml}
              pdata={row}
              position="list"
              onRefresh={(record: string, followRecordId: string) =>
                onRefreshTable(record, followRecordId)
              }
              isShowAll={true}
            ></LastRecord>
          </div>
        </div>,
      ]
    },
    renderCell(renderOpts, params) {
      const timerId = uuid()
      timeStart(timerId)

      const { row, column } = params
      const { events, props } = renderOpts
      const recordHtml: string = row[column.field]
      // const recordText: string = row[column.field]?.replace(/<[^>]+>|&nbsp;/g, '')
      // const recordId: string = row.followRecordId
      const onRefreshTable = (record: string, followRecordId: string) => {
        events?.refreshTable({
          ...row,
          followRecord: record,
          followRecordId,
        })
      }
      const isFund = props?.code === 'fund'
      timeEnd(timerId, FOLLOW_RECORD_RENDERER)

      return [
        <LastRecord
          recordHtml={recordHtml}
          pdata={row}
          position="list"
          onRefresh={(record: string, followRecordId: string) =>
            onRefreshTable(record, followRecordId)
          }
        ></LastRecord>,
      ]
    },
  })
}

export const getTableRenderer = (type: string) => {
  return VXETable.renderer.get(type)
}
