// 台账列表单行文本，多行文本（VARCHAR, TEXT）支持超链接跳转
<template>
  <span class="text-cont">
    <autoTooltip content-class="input-field-input-cont" :content="text" />
  </span>
</template>

<script>
import autoTooltip from '@/components/AutoTooltip.vue'
export default {
  name: "InputFiled",
  components: {
    autoTooltip
  },
  props: {
    value: String | Object
  },
  computed: {
    text() {
      if (!this.value) return '-'
      // 文本字段如果输入链接需要支持跳转
      const urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g;
      let htmlNote = this.value?.replace(/(<\/?a.*?>)/g, ''); // 去除a标签
      htmlNote = this.value.replace(urlPattern, (match) => {
        const urlHttp = match.indexOf('http://') && match.indexOf('https://');
        const url = urlHttp === -1 ? match.split('/')[0] : match.split('/')[2]; // 不包含http
        const href = urlHttp === -1 ? `https://${match}` : match; // 包含http
        return `<a target="_blank" href="${href}" style="color: #3298FF">${match}</a>`;
      });
      return htmlNote
    }
  },
}
</script>

<style lang="scss" scoped>
.text-cont {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
<style lang="scss">
 .arco-tooltip-content {
    &.input-field-input-cont {
      max-height: 300px;
      overflow: auto;
    }
  }
</style>