import { nextTick, onMounted, Ref, ref, VNode } from 'vue'

const useEllipsis = (domRef: Ref<HTMLElement>) => {
  const isOverflow = ref(false)

  const doCalc = (target: Element) => {
    const { width = 0, height = 0 } = target?.getBoundingClientRect() ?? {}
    const parent = target?.parentNode as Element
    if (parent) {
      const parentWidth = parent.clientWidth
      const parentHeight = parent.clientHeight

      isOverflow.value = Math.round(width) > parentWidth || Math.round(height) > parentHeight
    }
  }

  const observer = new ResizeObserver(function (entries) {
    const entry = entries[0]
    const cr = entry.contentRect
    const target = entry.target

    doCalc(target)
  })

  const doReCalc = () => {
    doCalc(domRef.value)
  }

  onMounted(() => {
    // TODO: ref.el || ref
    const dom = domRef.value

    observer.observe(dom)

    // nextTick(doReCalc)
  })

  return {
    isOverflow,
    doReCalc,
  }
}

export default useEllipsis
